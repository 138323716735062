export function shortDateStr(dt: Date | string) {
  if (typeof dt == 'string') {
    dt = new Date(dt);
  }
  return dt.toLocaleDateString('en-AU', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
}

export function longDateStr(dt: Date | string) {
  if (typeof dt == 'string') {
    dt = new Date(dt);
  }
  return dt.toLocaleDateString('en-AU', {
    weekday: 'long',
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  });
}

export function timeStr(dt: Date | string) {
  if (typeof dt == 'string') {
    dt = new Date(dt);
  }
  return dt.toLocaleTimeString('en-AU', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  });
}

export function dateTimeFromJobLocationInterval(
  jobLocationInterval?: {
    startDate?: string;
    startTime?: string;
    endDate?: string;
    endTime?: string;
  } | null,
) {
  if (
    !jobLocationInterval ||
    !jobLocationInterval.startDate ||
    !jobLocationInterval.startTime ||
    !jobLocationInterval.endDate ||
    !jobLocationInterval.endTime
  )
    return {
      startDateTime: new Date(),
      endDateTime: new Date(),
    };

  try {
    return {
      startDateTime: new Date(
        jobLocationInterval.startDate + 'T' + jobLocationInterval.startTime,
      ),
      endDateTime: new Date(
        jobLocationInterval.endDate + 'T' + jobLocationInterval.endTime,
      ),
    };
  } catch {
    return {
      startDateTime: new Date(),
      endDateTime: new Date(),
    };
  }
}
