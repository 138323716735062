<script lang="ts">
export default { inheritAttrs: false };
</script>

<script setup lang="ts">
import { IonButton, IonGrid, IonRow, IonCol } from '@ionic/vue';

defineProps({
  pulse: { type: Boolean, default: false },
});
</script>

<template>
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-button
          class="ion-button-class ion-text-wrap"
          :class="{ pulsed: pulse }"
          v-bind="$attrs"
          shape="round"
          style="--box-shadow: none"
        >
          <slot></slot>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<style scoped>
.ion-button-class {
  /*height:56px;*/
  margin: auto;
}

.pulsed {
  animation: pulseAnimationStrike 1.5s infinite;
}

@keyframes pulseAnimationStrike {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
</style>
