import { defineStore } from 'pinia';
import { AddressResponse, EmergencyContactResponse } from '@shared/util';
import { ref } from 'vue';
import {
  ISOStringToDateInput,
  formatDateInputValue,
} from '@/lib/format-time-date-input-values';
import { InfoPagesTitles } from '@/lib/constants';
import { OngoingBookingPricing, useCustomerStore } from './customer';
import {
  BookingType,
  BookingStatus,
  BookingResponse,
  DrivingType,
} from '@shared/bookings';
import {
  PriceBookingDateResponse,
  PriceBookingResponse,
} from '@shared/pricing';
import {
  SitterResponse,
  SitterStatus,
  SitterStatusLogItemResponse,
} from '@shared/sitters';
import { UserResponse } from '@shared/users';
import { ContactUsResponse, FAQResponse, InfoPage } from '@shared/franchises';
import { CustomerResponse } from '@shared/customers';
import {
  ChildResponse,
  NdisFundingAllocationType,
  ParentResponse,
} from '@shared/parents';
import {
  EventBookingResponse,
  EventBookingType,
  eventBookingTitles,
} from '@shared/event-bookings';
import {
  ArtistType,
  EventType,
  PartyBookingResponse,
} from '@shared/party-bookings';
import { OngoingBookingResponse } from '@shared/ongoing-bookings';
import { ResourceResponse } from '@shared/resources';

// a base class for all forms, which has an optional _validationErrors property
export class Form {
  public _validationErrors?: Record<string, string>; // a map of field names to arrays of error messages
  public _generalError?: string;

  constructor() {
    this._validationErrors = {};
    this._generalError = '';
  }
}

export class LoginForm extends Form {
  email?: string;
  password?: string;
  rememberMe?: boolean;
  messagingToken?: string;
}

export class ResetPasswordForm extends Form {
  password?: string;
  passwordConfirmation?: string;
  token?: string;
}

export class SitterStatusForm extends Form {
  status: SitterStatus;
  comment: string;
  constructor(status?: SitterStatus) {
    super();
    if (status) {
      this.status = status;
    }
  }
}

export class ContactUsForm extends Form {
  email?: string;
  phone?: string;
  officeHours?: string;
  address?: AddressForm;
  emergencyHours?: string;

  constructor(contactUs?: ContactUsResponse) {
    super();
    if (contactUs) {
      this.email = contactUs.email;
      this.phone = contactUs.phone;
      this.officeHours = contactUs.officeHours;
      this.address = new AddressForm(contactUs.address);
      this.emergencyHours = contactUs.emergencyHours;
    }
  }
}

export class FAQForm extends Form {
  _id?: string;
  question?: string;
  answer?: string;
  userType?: string;
  modifiedBy?: UserResponse | null;
  lastModified?: string;

  constructor(faq: FAQResponse | string = '') {
    super();
    if (typeof faq == 'string') {
      this.question = '';
      this.answer = '';
      this.userType = faq;
      this.modifiedBy = null;
      this.lastModified = '';
    } else if (faq) {
      this._id = faq._id;
      this.question = faq.question;
      this.answer = faq.answer;
      this.userType = faq.userType;
      this.modifiedBy = faq.modifiedBy;
      this.lastModified = formatDateInputValue(faq.updatedAt);
    }
  }
}

export class InfoPageForm extends Form {
  content?: string;
  modifiedBy?: UserResponse | null;
  lastModified?: string;
  tag?: string;
  title?: string;

  constructor(infoPageOrTag: InfoPage | string = '') {
    super();
    if (typeof infoPageOrTag == 'string') {
      this.content = '';
      this.modifiedBy = null;
      this.lastModified = '';
      this.tag = infoPageOrTag;
      this.title = InfoPagesTitles[infoPageOrTag];
    } else if (infoPageOrTag) {
      const infoPage = infoPageOrTag as InfoPage;
      this.content = infoPage.content;
      this.modifiedBy = infoPage.modifiedBy;
      this.lastModified = formatDateInputValue(infoPage.updatedAt);
      this.tag = infoPage.tag;
      this.title = infoPage.title;
    }
  }
}

export class ResourceForm extends Form {
  _id?: string;
  parentResourceId?: string;
  title: string;
  url?: string;
  group: boolean;
  resources: ResourceResponse[];

  constructor(resourceResponse?: ResourceResponse) {
    super();
    if (resourceResponse) {
      this._id = resourceResponse._id;
      this.title = resourceResponse.title;
      this.url = resourceResponse.url;
      this.group = !resourceResponse.url;
      this.resources = resourceResponse.resources;
    } else {
      this.group = false;
      this.title = '';
      this.url = '';
    }
  }
}

export class SitterForm extends Form {
  _id?: string;
  email: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  phone: string | undefined;
  status: SitterStatus;
  sitterStatusLog: SitterStatusLogItemResponse[];
  subscribe?: boolean; // default true
  homeAddress: AddressForm;
  password: string | undefined;
  passwordConfirmation: string | undefined;
  emergencyContact: EmergencyContactForm;
  dateOfBirth: string | undefined;

  constructor(user?: UserResponse) {
    super();
    if (user && user.userType == 'Sitter') {
      this.dateOfBirth = user.organisation.dateOfBirth;
      this.email = user.email;
      this.emergencyContact = new EmergencyContactForm(
        user.organisation.emergencyContact,
      );
      this.firstName = user.firstName;
      this.status = user.organisation.status;
      this.sitterStatusLog = user.organisation.sitterStatusLog;
      this.homeAddress = new AddressForm(user.organisation.homeAddress);
      this.lastName = user.lastName;
      this.phone = user.organisation.phone;
      this.subscribe = user.subscribe;
      this._id = user.organisation._id;
    } else {
      this.homeAddress = new AddressForm();
      this.emergencyContact = new EmergencyContactForm();
    }
  }
}

export class SitterQuestionsForm extends Form {
  experience: string | undefined;
  love: string | undefined;
  spareTime: string | undefined;
  secretTalents: string | undefined;
  favouriteActivity: string | undefined;
  superhero: string | undefined;

  constructor(sitter?: SitterResponse) {
    super();
    if (sitter) {
      this.experience = sitter.experience;
      this.love = sitter.love;
      this.spareTime = sitter.spareTime;
      this.secretTalents = sitter.secretTalents;
      this.favouriteActivity = sitter.favouriteActivity;
      this.superhero = sitter.superhero;
    }
  }
}

export class AddressForm extends Form {
  unitNumber?: string;
  streetNumber?: string;
  street?: string;
  city?: string;

  state?: string;
  postcode?: string;

  latitude?: number;
  longitude?: number;

  placeId?: string;

  isPopulated: boolean;

  constructor(
    sourceAddress?: google.maps.places.PlaceResult | AddressResponse,
  ) {
    super();
    if (sourceAddress && !('address_components' in sourceAddress)) {
      const localSourceAddress = sourceAddress as AddressResponse;
      this.unitNumber = localSourceAddress.unitNumber ?? undefined;
      this.streetNumber = localSourceAddress.streetNumber;
      this.street = localSourceAddress.street;
      this.city = localSourceAddress.city;
      this.state = localSourceAddress.state;
      this.postcode = localSourceAddress.postcode;
      this.latitude = localSourceAddress.latitude ?? undefined;
      this.longitude = localSourceAddress.longitude ?? undefined;
      this.placeId = localSourceAddress.placeId ?? undefined;
    } else if (sourceAddress) {
      const unitNumber = sourceAddress.address_components?.find((component) =>
        component.types.includes('subpremise'),
      )?.short_name;
      const streetNumber = sourceAddress.address_components?.find((component) =>
        component.types.includes('street_number'),
      )?.short_name;
      const street = sourceAddress.address_components?.find((component) =>
        component.types.includes('route'),
      )?.short_name;

      this.placeId = sourceAddress.place_id;
      this.unitNumber = unitNumber ?? '';
      this.streetNumber = streetNumber ?? '';
      this.street = street ?? '';
      this.city =
        sourceAddress.address_components?.find((component) =>
          component.types.includes('locality'),
        )?.short_name ?? '';
      this.state =
        sourceAddress.address_components?.find((component) =>
          component.types.includes('administrative_area_level_1'),
        )?.short_name ?? '';
      this.postcode =
        sourceAddress.address_components?.find((component) =>
          component.types.includes('postal_code'),
        )?.short_name ?? '';
      this.latitude = sourceAddress.geometry?.location?.lat();
      this.longitude = sourceAddress.geometry?.location?.lng();
    }
    this.isPopulated = !!sourceAddress;
  }
}

export class EmergencyContactForm extends Form {
  name?: string;
  phone?: string;
  relationship?: string;
  constructor(emergencyContact?: EmergencyContactResponse) {
    super();
    if (emergencyContact) {
      this.name = emergencyContact.name;
      this.phone = emergencyContact.phone;
      this.relationship = emergencyContact.relationship;
    }
  }
}

export class CasualBookingDateForm extends Form {
  startDate?: string;
  endDate?: string;
  startTime?: string;
  endTime?: string;
  children: Array<string | number>;

  constructor() {
    super();
    this.children = [];
  }
}

export class UpdateBookingForm extends CasualBookingDateForm {
  address?: AddressForm;
  parent?: ParentResponse;
  addressNotes?: string;
  updateReason?: string;
  constructor(booking?: BookingResponse) {
    super();
    if (booking) {
      const updatedChildren =
        booking.updatedChildren && booking.updatedChildren.length > 0
          ? booking.updatedChildren.map((child) => child._id)
          : booking.children.map((child) => child._id);
      const updatedAddressNotes = booking.updatedAddressNotes;
      const updatedAddress = booking.updatedAddress;

      /* NOTE: If we to use the updated values, we will need to re-implement the code below.
      FURHTER NOTE: Be sure to convert the updatedInterval to the job timezone (not the current
        user).
        
      const updatedInterval = booking.updatedInterval;
      const start = updatedInterval
        ? updatedInterval.start
        : booking.interval.start;
      const end = updatedInterval ? updatedInterval.end : booking.interval.end;
      const startDate = format(new Date(start), 'yyyy-MM-dd');
      const endDate = format(new Date(end), 'yyyy-MM-dd');

      const startTime = format(new Date(start), 'HH:mm:ss');
      const endTime = format(new Date(end), 'HH:mm:ss');
      */
      // format the date and time
      this.startDate = booking.jobLocationInterval.startDate;
      this.endDate = booking.jobLocationInterval.endDate;
      this.startTime = booking.jobLocationInterval.startTime;
      this.endTime = booking.jobLocationInterval.endTime;
      this.children = updatedChildren;
      this.parent = booking.parent;
      this.addressNotes = updatedAddressNotes ?? booking.addressNotes;

      this.address = new AddressForm(updatedAddress ?? booking.address);
      this.updateReason = booking.updateReason || '';
    }
  }
}

export class ConfirmUpdateBookingForm extends Form {
  accept?: boolean;
  bookingId?: string;
  message?: string | undefined;

  constructor(accept: boolean, bookingId: string, message?: string) {
    super();
    this.accept = accept;
    this.bookingId = bookingId;
    this.message = accept ? undefined : message;
  }
}

export class CasualBookingForm extends Form {
  _id?: string;

  parent?: string;

  freeParkingAvailable?: boolean;
  parkingDetails?: string;

  bookingDates: Array<CasualBookingDateForm>;

  useHomeAddress?: boolean;
  address: AddressForm;
  addressNotes?: string;

  favouritesFirst?: boolean;
  requestedSitters?: Array<string | number>;
  requestedSitterName?: string;
  allowFallbackSitters?: boolean;

  drivingIsRequired?: boolean;
  vehicleType?: DrivingType;
  drivingDetails?: string;

  reason?: string;

  otherDetails?: string;

  termsAgreed: boolean;
  packSelection: number;

  useSavedPaymentMethod: boolean;

  // retrieved from the server when
  // the booking is priced.
  pricing?: PriceBookingResponse;

  // returned from the server during payment process.
  clientSecret?: string;
  paymentIntentId?: string;
  totalBookingFeeCharged?: string;
  paymentRequired?: boolean;
  bookingFeePaymentType?: string;
  xeroInvoiceUrl?: string;

  constructor() {
    super();
    this.address = new AddressForm();
    this.bookingDates = [new CasualBookingDateForm()];
    this.termsAgreed = false;
    this.packSelection = 0;
    this.useSavedPaymentMethod = false;
  }
}

export class ManualBookingForm extends Form {
  _id?: string;

  parent?: string;

  freeParkingAvailable?: boolean;
  parkingDetails?: string;

  jobLocationInterval: {
    startDate?: string;
    startTime?: string;
    endDate?: string;
    endTime?: string;
  };

  children: Array<string>;

  address: AddressForm;
  addressNotes?: string;

  favouritesFirst?: boolean;
  requestedSitters?: Array<string | number>;
  requestedSitterName?: string;
  allowFallbackSitters?: boolean;

  drivingIsRequired?: boolean;
  vehicleType?: DrivingType;
  drivingDetails?: string;

  reason?: string;

  otherDetails?: string;

  status?: BookingStatus;

  bookingType: BookingType;

  // returned from the server during payment process.
  clientSecret?: string;
  paymentIntentId?: string;
  totalBookingFeeCharged?: string;
  paymentRequired?: boolean;
  bookingFeePaymentType?: string;
  xeroInvoiceUrl?: string;

  useSavedPaymentMethod: boolean;

  constructor() {
    super();
    this.address = new AddressForm();
    this.jobLocationInterval = {};
    this.children = [];
    this.bookingType = 'CasualBooking';
    this.useSavedPaymentMethod = false;
  }
}

export class PartyBookingForm extends Form {
  id?: string | number;

  artistTypes: Array<ArtistType>;
  eventContactName?: string;
  eventContactPhone?: string;

  birthdayAge?: string; // for birthday parties
  eventType?: EventType; // for party bookings
  eventName?: string; // for party bookings

  freeParkingAvailable?: boolean;
  parkingDetails?: string;

  dateOfBooking?: string;
  startTime?: string;
  endTime?: string;
  numChildren?: string;

  address?: AddressForm;
  addressNotes?: string;

  locationOutdoors?: boolean;
  weatherPlan?: string;

  otherDetails?: string;

  constructor(partyBookingResponse?: PartyBookingResponse) {
    super();
    if (partyBookingResponse) {
      Object.assign(this, partyBookingResponse);
      this.address = new AddressForm(partyBookingResponse.address);
      this.id = partyBookingResponse._id;
    } else {
      this.artistTypes = [];
    }
  }
}

// a function to check if a booking is an invoice booking.  An invoice booking is
// one where any of the children in the BookingForm.booking_dates have invoice_required
// set to true, or api.user.role is company.
function isInvoiceBooking(booking: CasualBookingForm) {
  const customerStore = useCustomerStore();

  if (customerStore.isCompany) {
    return true;
  }

  const parent = customerStore.parents.find(
    (parent: ParentResponse) => parent._id == booking.parent,
  );
  if (!parent) {
    return false;
  }

  booking.bookingDates.forEach((bookingDate) => {
    if (
      bookingDate.children.some(
        (childId) =>
          parent.children.find((child: ChildResponse) => child._id == childId)
            ?.ndisFundingAllocation == 'manualAllocation',
      )
    ) {
      return true;
    }
  });

  return false;
}

export class BookingRuleForm extends Form {
  vueId: number;
  startDate?: string;
  startTime?: string;
  endTime?: string;
  endDate?: string;
  frequency: number;
  children: Array<string | number>;

  constructor() {
    super();
    this.frequency = 7;
    this.children = [];
    this.vueId = new Date().getTime();
  }
}

export class OngoingBookingForm extends Form {
  id?: string | number;
  parent?: string | number;

  bookingRules: Array<BookingRuleForm>;

  isFlexible?: boolean;
  atHomeAddress?: boolean;
  address?: AddressForm;
  addressNotes?: string;

  freeParkingAvailable?: number | boolean;
  parkingDetails?: string;
  drivingIsRequired?: boolean;
  vehicleType?: string;
  parentAtHome?: boolean;
  aTypicalDay?: string;

  clientSecret?: string;
  paymentIntentId?: string;

  paymentRequired?: boolean;

  useSavedPaymentMethod: boolean;

  // retrieved from the server when
  // the booking is priced.
  pricing?:
    | OngoingBookingPricing
    | {
        pricingType: 'manual';
        manualHandlingRequired: true;
        customerMessage: string;
        adminMessage: string;
      };

  constructor(ongoingBookingResponse?: OngoingBookingResponse) {
    super();
    if (ongoingBookingResponse) {
      this.id = ongoingBookingResponse._id;
      this.parent = ongoingBookingResponse.parent._id;
      this.bookingRules = ongoingBookingResponse.bookingDateTime.map(
        (rule, idx) => {
          return {
            ...rule,
            vueId: idx,
          };
        },
      );
      this.isFlexible = ongoingBookingResponse.isFlexible;
      this.atHomeAddress = false;
      this.address = new AddressForm(ongoingBookingResponse.address);
      this.addressNotes = ongoingBookingResponse.addressNotes;
      this.parkingDetails = ongoingBookingResponse.parkingDetails;
      if (this.parkingDetails) {
        this.freeParkingAvailable = false;
      } else {
        this.freeParkingAvailable = true;
      }
      this.drivingIsRequired = ongoingBookingResponse.vehicleType !== '';
      this.vehicleType = ongoingBookingResponse.vehicleType;
      this.parentAtHome = ongoingBookingResponse.parentAtHome;
      this.aTypicalDay = ongoingBookingResponse.aTypicalDay;
      this.useSavedPaymentMethod = false;
      this.pricing = undefined;
    } else {
      this.bookingRules = [new BookingRuleForm()];
      this.useSavedPaymentMethod = false;
    }
  }
}

export class EventBookingDateForm extends Form {
  date?: string;
  startTime?: string;
  endTime?: string;
  numChildren?: string;
}

export class EventBookingForm extends Form {
  _id?: string | number;
  eventType?: EventBookingType;
  eventTypeChoices = eventBookingTitles;
  eventTypeDetails?: string;
  eventContactName?: string;
  eventContactPhone?: string;
  eventContactEmail?: string;
  weddingCoordinatorName?: string;
  weddingCoordinatorPhone?: string;
  bookingDates: Array<EventBookingDateForm>;
  address?: AddressForm;
  addressNotes?: string;
  childrenAges?: string;
  childrenOutOfNappies?: 0 | 1 | boolean;

  freeParkingAvailable?: boolean;
  parkingDetails?: string;
  otherDetails?: string;

  constructor(eventBookingResponse?: EventBookingResponse) {
    super();
    if (eventBookingResponse) {
      Object.assign(this, eventBookingResponse);
      this.address = new AddressForm(eventBookingResponse.address);
      this.bookingDates = eventBookingResponse.bookingDateTime.map(
        (date) => date,
      );
      if (typeof this.childrenOutOfNappies === 'number') {
        this.childrenOutOfNappies = this.childrenOutOfNappies === 1;
      }
    } else {
      this.bookingDates = [new EventBookingDateForm()];
    }
  }
}

export class ChildForm extends Form {
  _id?: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string;
  hasMedicalConditions?: boolean;
  medicalConditions?: string;
  hasSpecialNeeds?: boolean;
  specialNeeds?: string;
  isNdisParticipant?: boolean;
  ndisNumber?: string;
  ndisFundingAllocation?: NdisFundingAllocationType;
  ndisInvoiceEmail?: string;
  otherDetails?: string;

  constructor(child?: ChildResponse) {
    super();
    if (child) {
      this._id = child._id;
      this.firstName = child.firstName;
      this.lastName = child.lastName;
      this.dateOfBirth = ISOStringToDateInput(child.dateOfBirth);
      this.hasMedicalConditions = child.medicalConditions != '';
      this.medicalConditions = child.medicalConditions;
      this.hasSpecialNeeds = child.specialNeeds != '';
      this.specialNeeds = child.specialNeeds;
      this.isNdisParticipant = child.ndisNumber != '';
      this.ndisNumber = child.ndisNumber;
      this.ndisFundingAllocation = child.ndisFundingAllocation;
      this.ndisInvoiceEmail = child.ndisInvoiceEmail;
      this.otherDetails = child.otherDetails;
    }
  }
}

export class ParentForm extends Form {
  _id?: string | number;
  customer: string | number;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  homeAddress: AddressForm;
  homeAddressNotes?: string;
  emergencyContact: EmergencyContactForm;
  hasPets?: boolean;
  petDetails?: string;

  constructor(customer?: string | number, parent?: ParentResponse) {
    super();
    this.homeAddress = new AddressForm();
    this.emergencyContact = new EmergencyContactForm();
    this.customer = customer || '';

    if (parent) {
      this._id = parent._id;
      this.firstName = parent.firstName;
      this.lastName = parent.lastName;
      this.email = parent.email;
      this.phone = parent.phone;
      this.homeAddress = new AddressForm(parent.homeAddress);
      this.homeAddressNotes = parent.homeAddressNotes;
      this.emergencyContact = new EmergencyContactForm(parent.emergencyContact);
      this.hasPets = parent.petDetails != '';
      this.petDetails = parent.petDetails;
    }
  }
}

export class CompanyForm extends Form {
  _id?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  companyName?: string;
  abn?: string;
  subscribe?: boolean;

  constructor(company?: CustomerResponse, user?: UserResponse) {
    super();
    this.companyName = company?.companyName;
    this._id = company?._id;
    this.abn = company?.abn;
    this.phone = company?.phone;

    // subcribe handling
    this.email = user?.email;
    this.firstName = user?.firstName;
    this.lastName = user?.lastName;
    this.subscribe = user?.subscribe;
  }
}

//TODO: implement this
export class ChangePasswordForm extends Form {
  oldPassword?: string;
  password?: string;
  passwordConfirmation?: string;
}

export class CustomerForm extends Form {
  // all customer types
  _id?: string | number;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  subscribe?: boolean;
  isCompany: boolean;

  // Details for only Parents
  parent: ParentForm;

  // Details for company only
  companyName?: string;
  abn?: string;

  constructor(
    customer?: CustomerResponse,
    user?: UserResponse,
    parent?: ParentResponse,
  ) {
    super();
    this.parent = new ParentForm();
    this.isCompany = false;

    if (customer) {
      if (!user) {
        throw new Error('If you specify a customer, then a user is required');
      }
    } else {
      return;
    }

    customer = customer as CustomerResponse;
    user = user as UserResponse;
    this.isCompany = customer?.isCompany;
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.email = user.email;
    this.subscribe = user.subscribe;
    if (!customer.isCompany) {
      if (!parent) {
        throw new Error('Parent is required for non-company customers');
      }
      this.parent = new ParentForm(customer._id, parent);
    } else {
      this.companyName = customer.companyName;
      this.abn = customer.abn;
      this.phone = customer.phone;
    }
  }
}

export class CompleteBookingForm extends Form {
  bookingId?: string;
  startDate?: string;
  startTime?: string;
  endDate?: string;
  endTime?: string;
  numChildren?: string; // because this will be filled by an input, it is a string.
  pricing?: PriceBookingDateResponse;
  confirm: boolean;

  constructor(booking?: BookingResponse) {
    super();
    this.confirm = false;
    if (booking) {
      this.bookingId = booking._id;
      this.startDate = booking.jobLocationInterval?.startDate;
      this.endDate = booking.jobLocationInterval?.endDate;
      this.startTime = booking.jobLocationInterval?.startTime;
      this.endTime = booking.jobLocationInterval?.endTime;
      this.numChildren = booking.children.length.toString();
    }
  }
}

export const useFormStore = defineStore('forms', () => {
  const casualBookingForm = ref(new CasualBookingForm());
  const partyBookingForm = ref(new PartyBookingForm());

  const ongoingBookingForm = ref(new OngoingBookingForm());

  const eventBookingForm = ref(new EventBookingForm());

  const manualBookingForm = ref(new ManualBookingForm());

  const completeBookingForm = ref(new CompleteBookingForm());

  return {
    casualBookingForm,
    partyBookingForm,
    ongoingBookingForm,
    eventBookingForm,
    manualBookingForm,
    completeBookingForm,
    isInvoiceBooking,
  };
});
