<script setup lang="ts">
import {
  IonApp,
  IonRouterOutlet,
  IonButton,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/vue';

// some of the vue router capabilities are not in ionic router.
import {
  useApiStore,
  useCustomerStore,
  useFormStore,
  useSitterStore,
} from './store';
import { close } from 'ionicons/icons';
/* mp035 import capacitor app for hooks */
import { App as CapacitorApp } from '@capacitor/app';
import {
  registerPushNotifications,
  addPushNotificationReceivedListener,
} from './lib/push-notifications';
import MyModal from './components/MyModal.vue';

import { useAdminStore } from './store/admin';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { PushNotificationSchema } from '@capacitor/push-notifications';
import { PluginListenerHandle } from '@capacitor/core';
// allow cypress tests to access the stores
if (window.Cypress) {
  (window as any).apiStore = useApiStore();
  (window as any).customerStore = useCustomerStore();
  (window as any).sitterStore = useSitterStore();
  (window as any).adminStore = useAdminStore();
  (window as any).formStore = useFormStore();
}

const appIsActive = ref(true);
const showPushNotificationModal = ref(false);
const displayedNotification = ref<PushNotificationSchema>();
let pushNotificationsListener: PluginListenerHandle | undefined;
let appStateListener: PluginListenerHandle;
// call register when the app first starts,
onMounted(async () => {
  await registerPushNotifications(true);
  pushNotificationsListener = await addPushNotificationReceivedListener(
    (notification) => {
      displayedNotification.value = notification;
      showPushNotificationModal.value = true;
    },
  );

  appStateListener = await CapacitorApp.addListener(
    'appStateChange',
    (state) => {
      // call register again everytime the app is opened to
      // update the push token
      if (state.isActive) {
        registerPushNotifications(false);
      }

      appIsActive.value = state.isActive;
    },
  );
});

onBeforeUnmount(() => {
  // technically not required, with the current app layout
  // because this component should never be unmounted,
  // but it's good practice to remove listeners
  pushNotificationsListener?.remove();
  appStateListener?.remove();
});
</script>

<template>
  <ion-app>
    <!-- <router-view></router-view> -->
    <ion-router-outlet></ion-router-outlet>
    <my-modal
      v-model="showPushNotificationModal"
      style="background-color: white"
    >
      <ion-grid>
        <ion-row>
          <ion-col
            style="display: flex; align-items: center"
            class="ion-padding-start"
          >
            <h4>{{ displayedNotification?.title }}</h4>
          </ion-col>
          <ion-col size="auto" style="display: flex; align-items: center">
            <ion-button @click="showPushNotificationModal = false" fill="clear">
              <ion-icon slot="icon-only" :icon="close"></ion-icon>
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row class="ion-padding-horizontal ion-padding-bottom">
          <ion-col>
            {{ displayedNotification?.body }}
          </ion-col>
        </ion-row>
      </ion-grid>
    </my-modal>
  </ion-app>
</template>

<!-- style scoped>
ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

.menu-button-class {
  --background: #fff;
  --box-shadow: none;
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;

  margin-bottom: 18px;

  color: #757575;

  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}
</style -->
