export function statusCentury(statuscode: number): number {
  return Math.floor(statuscode / 100);
}
export function statusOk(statuscode: number | HttpResponse): boolean {
  if (typeof statuscode == 'object') {
    statuscode = statuscode.status;
  }
  return statusCentury(statuscode) == 2;
}

export interface HttpRequestOptions {
  bodyParams?: Record<string, any>;
  queryParams?: Record<string, any>;
  authToken?: string | null;
  headers?: { [key: string]: string };
  useFormData?: boolean;
  timeout?: number;
}

export interface HttpResponse {
  body: any;
  headers: Headers;
  status: number;
}

export async function httpRequest(
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  url: string,
  options?: HttpRequestOptions,
): Promise<HttpResponse> {
  // create an empty options object if one is not provided
  // (for coding simplicity only)
  options ||= {};

  options.headers ||= {};

  options.headers['Accept'] ||= 'application/json';

  let bodyStr;

  if (
    method != 'GET' &&
    method != 'DELETE' &&
    options.bodyParams !== undefined
  ) {
    if (options.useFormData) {
      bodyStr = new FormData();
      for (const key in options.bodyParams) {
        bodyStr.append(key, options.bodyParams[key]);
      }
    } else {
      bodyStr = JSON.stringify(options.bodyParams);
      options.headers['Content-Type'] ||= 'application/json';
    }
  }

  // populate authorization headers if provided
  if (options.authToken) {
    options.headers['Authorization'] = 'Bearer ' + options.authToken;
  }

  // add query parameters to url if provided
  if (options.queryParams) {
    const queryParams = new URLSearchParams(options.queryParams);
    url += '?' + queryParams.toString();
  }

  console.log(`httpRequest(${method}, ${url})`);

  const fetchOptions = {
    method: method,
    headers: options.headers,
    body: bodyStr,
    signal: undefined as AbortSignal | undefined,
  };

  if (options.timeout !== 0 && process.env.NODE_ENV != 'development') {
    if (options.timeout === undefined) {
      options.timeout = 15000; // default to 15 seconds
    }
    fetchOptions.signal = AbortSignal.timeout(options.timeout);
  }

  let rStatus;

  try {
    const r = await fetch(url, fetchOptions);
    rStatus = r.status;
    if (r.status == 401) {
      // unauthorised
      console.log('Received 401 (Unauthorised) from endpoint ' + url);
    }

    // for error reporting, and returning as text.
    const responseClone = r.clone();
    const contentType = r.headers.get('content-type');
    if (contentType != null && contentType.includes('application/json')) {
      try {
        const js = await r.json();
        return {
          body: js,
          status: r.status,
          headers: r.headers,
        };
      } catch (ex: any) {
        console.error('Error decoding JSON response from apiRequest.');
        if ('message' in ex) {
          console.error(ex.message);
        }
        console.error('Content of the response was:');
        console.error(r);
        const rText = await responseClone.text();
        console.error('Text content of the response body was:');
        console.error(rText);
      }
    } else if (
      process.env.NODE_ENV == 'development' &&
      statusOk(r.status) &&
      contentType != null &&
      contentType.includes('text/html')
    ) {
      // if the response was text/html it is likely
      // a dd or message page so open in a new tab,
      // unless it is the 404 redirect to the base html page.
      console.log('API request returned plain HTML, processing....');
      console.log(contentType);
      const html = await r.text();
      if (!html.includes('*SPABASE*')) {
        const tab = window.open('about:blank', '_blank');
        if (tab !== null) {
          tab.document.write(html); // where 'html' is a variable containing your HTML
          tab.document.close(); // to finish loading the page
        } else {
          console.error('Unable to open new tab to display error HTML!');
        }
      } else {
        console.error(
          `API request returned the HTML SPA framework.  This means the request endpoint (${method}: ${url}) was not found.`,
        );
      }
    } else if (contentType != null && statusOk(r.status)) {
      // Download binary files
      const blob = await r.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve({
            status: r.status,
            headers: r.headers,
            body: reader.result,
          });
        };
        reader.onerror = reject;

        reader.readAsDataURL(blob);
      });
    }
  } catch (ex) {
    // errors here indicate some network problem other than HTTP error codes
    // so we can safely assume that we are offline if something is caught here.
    console.error('Exception in promise of apiRequest fetch stage:');
    console.error('URL:', url);
    console.error('status:', rStatus);
    console.error(ex);
    return Promise.resolve({
      status: 0,
      body: ex,
      headers: new Headers(),
    });
  }

  // if we have not returned yet, then the function did not know what to do with the response.
  return Promise.resolve({
    status: 0,
    body: { message: 'Unable to interpret response from back-end.' },
    headers: new Headers(),
  });
}
