import { defineStore } from 'pinia';

import {
  Payment,
  /* functions / instances */
  useApiStore,
} from '.';

import { ref, computed, Ref } from 'vue';
import { apiRequest, updateRefFromApi } from '@/lib/api-request';
import { CustomerResponse } from '@shared/customers';
import { ParentResponse } from '@shared/parents';
import { SitterResponse } from '@shared/sitters';
import { FAQResponse, InfoPage } from '@shared/franchises';
import {
  CasualBookingsController,
  CustomersController,
  OngoingBookingsController,
  ParentsController,
  PartyBookingsController,
  PaymentsController,
  SupportActionsController,
} from '@/lib/api-routes';

export interface FindersFee {
  amount: string;
  accountingCode: string;
}

export interface Rates {
  weekday: number;
  sat: number;
  sun: number;
  publicHoliday: number;
  specialHoliday: number;
}

export interface OngoingSitterPricing {
  standardHourlyRate: string;
  minimumShiftHours: number;
  standardRateMaxChildren: number;
  additionalChildRate: Rates;
  penaltyRates: Rates;
}

export interface PartySitterPricing {
  minimumShiftHours: number;
}

export type OngoingBookingPricing = {
  _id: string;
  tag: string;
  findersFee: {
    amount: string;
    accountingCode: string;
  };
  managementFees: Array<[number, string]>;
  sitterPricing: {
    standardHourlyRate: string;
    minimumShiftHours: number;
    standardRateMaxChildren: number;
    additionalChildRate: {
      weekday: string;
      sat: string;
      sun: string;
      publicHoliday: string;
      specialHoliday: string;
    };
    penaltyRates: {
      weekday: string;
      sat: string;
      sun: string;
      publicHoliday: string;
      specialHoliday: string;
    };
  };
  paymentType: 'StripePayment' | 'XeroInvoice';
  savedCardLast4?: string | null;
  pricingType: 'automatic';
};

export interface PartyPricing {
  tag: string;
  sitterPricing: PartySitterPricing;
}

export const useCustomerStore = defineStore('customer', () => {
  const api = useApiStore();

  const customer = computed(() => {
    if (api.user?.userType != 'Customer') {
      if (api.representee?.userType == 'Customer') {
        return api.representee.organisation as CustomerResponse;
      } else {
        return undefined;
      }
    }
    return api.user.organisation as CustomerResponse;
  });

  const isCompany = computed(() => {
    return !!customer.value?.isCompany;
  });

  const isNotForProfit = computed(() => {
    return !!customer.value?.isNotForProfit;
  });

  // store the parents associated with the logged in User
  const parents = ref<Array<ParentResponse>>([]);

  async function loadParents() {
    return updateRefFromApi(parents, ParentsController.findAll);
  }

  const favouriteSitters = ref<Array<SitterResponse>>([]);

  // store the most recent batch of fetched payments.
  const payments = ref<Array<Payment>>([]);

  // the list of faq's loaded from the server
  const faqs = ref<Array<FAQResponse>>([]);

  // the list of price breaks loaded from the server
  const ongoingPricing = ref<OngoingBookingPricing>();
  const partyPricing = ref<PartyPricing>();

  async function loadOngoingPricing() {
    return await updateRefFromApi(
      ongoingPricing,
      OngoingBookingsController.pricing,
    );
  }

  async function loadPartyPricing() {
    return await updateRefFromApi(
      partyPricing,
      PartyBookingsController.pricing,
    );
  }

  const unPaidPaymentData = ref({
    paymentId: '',
    clientSecret: '',
    totalBookingFeeCharged: '',
  });

  async function loadUnPaidPaymentData(id: string) {
    return await updateRefFromApi(
      unPaidPaymentData,
      CasualBookingsController.getPaymentData,
      { routeParams: { id } },
    );
  }

  async function likeOrUnlikeSitter(sitter: { _id: string }, like: boolean) {
    console.log('likeOrUnlikeSitter', sitter, like);
    const likeSitterDto = {
      sitterId: sitter._id,
      likeSitter: like,
    };
    const result = await apiRequest(CustomersController.likeSitter, {
      bodyParams: likeSitterDto,
      showErrorModal: true,
    });
    return result.body.data;
  }

  async function loadFavouriteSitters(
    ref: Ref<SitterResponse | Array<SitterResponse> | undefined>,
    id?: string,
  ) {
    const query: Record<string, string> | undefined = id
      ? { id: id }
      : undefined;
    return await updateRefFromApi(
      ref,
      CustomersController.getFavouriteSitters,
      query,
    );
  }

  async function loadPreviousSitters(ref: Ref<Array<SitterResponse>>) {
    return await updateRefFromApi(ref, CustomersController.getPreviousSitters);
  }

  const parentChoices = computed(
    () =>
      new Map<string, string>(
        parents.value.map((parent) => [
          parent._id,
          parent.lastName + ', ' + parent.firstName,
        ]),
      ),
  );

  async function loadInfoPage(ref: Ref<InfoPage>, documentKey: string) {
    return await updateRefFromApi(ref, SupportActionsController.getInfoPages, {
      routeParams: { tag: documentKey },
    });
  }

  async function loadFaqs() {
    await updateRefFromApi(faqs, SupportActionsController.getCustomerFaqs);
  }

  async function loadPayments(
    startDateString?: string,
    endDateString?: string,
  ) {
    if (startDateString && endDateString) {
      return await updateRefFromApi(payments, PaymentsController.findAll, {
        queryParams: { start_date: startDateString, end_date: endDateString },
      });
    } else {
      return await updateRefFromApi(payments, PaymentsController.findAll);
    }
  }

  return {
    parents,
    parentChoices,
    favouriteSitters,
    payments,
    faqs,
    ongoingPricing,
    customer,
    isCompany,
    isNotForProfit,
    partyPricing,
    unPaidPaymentData,
    loadPartyPricing,
    loadOngoingPricing,
    likeOrUnlikeSitter,
    loadUnPaidPaymentData,
    loadFavouriteSitters,
    loadPreviousSitters,
    loadParents,
    loadInfoPage,
    loadFaqs,
    loadPayments,
  };
});
