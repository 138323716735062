import { PushNotifications } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { ref } from 'vue';
import { apiRequest } from './api-request';
import { appRouteTo } from '@/router';
import { UsersController } from './api-routes';

export const pushNotificationToken = ref<string | null>(null);

const addListeners = async () => {
  await PushNotifications.addListener('registration', async (token) => {
    console.log('Push registration success, token: ', token.value);
    localStorage.setItem('pushNotificationToken', token.value);
    pushNotificationToken.value = token.value;
    if (localStorage.getItem('token')) {
      // send the token to the server
      try {
        await apiRequest(UsersController.updateMessagingToken, {
          bodyParams: { messagingToken: token.value },
        });
      } catch (e) {
        console.error('Failed to send messaging token to server');
        console.error(e);
      }
    }
  });

  await PushNotifications.addListener('registrationError', (err) => {
    console.error('Registration error: ', err.error);
  });

  await PushNotifications.addListener(
    'pushNotificationReceived',
    (notification) => {
      console.log(
        'Push notification received: ',
        notification.data.messageType,
      );
    },
  );

  await PushNotifications.addListener(
    'pushNotificationActionPerformed',
    (event) => {
      console.log(
        'Push notification action performed',
        event.actionId,
        event.notification.data.messageType,
      );

      const routeTable: Record<string, Record<string, string>> = {
        // DEFINE THE ROUTES FOR THE PUSH NOTIFICATIONS HERE
        Sitter: {
          notification: 'sitter_notifications',
        },
        AdminGroup: {
          notification: 'admin_notifications',
        },
      };

      let routeName: string | undefined = undefined;
      const userType = localStorage.getItem('userType');
      if (userType) {
        routeName = routeTable[userType]?.[event.notification.data.messageType];
      }

      if (routeName) {
        appRouteTo(routeName);
      } else {
        console.log('Unknown push message type for', userType);
      }
    },
  );
};

export const registerPushNotifications = async (registerListeners: boolean) => {
  if (Capacitor.getPlatform() == 'web') {
    return;
  }

  // start with the stored version of the token, it will be updated
  // when a new token is registered.
  pushNotificationToken.value = localStorage.getItem('pushNotificationToken');

  let permStatus = await PushNotifications.checkPermissions();

  if (permStatus.receive === 'prompt') {
    console.log('Requesting permissions');
    permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive === 'granted') {
    console.log('Permissions granted');
    await PushNotifications.register();
    if (registerListeners) {
      await addListeners();
    }
  } else {
    throw new Error('User denied permissions!');
  }
};

export const getDeliveredPushNotifications = async () => {
  if (Capacitor.getPlatform() == 'web') {
    return [];
  }
  const notificationList = await PushNotifications.getDeliveredNotifications();
  console.log('delivered notifications', notificationList);
};

export const getNotificationToken = async () => {
  if (Capacitor.getPlatform() == 'web') {
    return;
  }
  return localStorage.getItem('pushNotificationToken');
};

// a simple wrapper that prevents errors on the web platform
export const addPushNotificationReceivedListener = async (
  callback: (data: any) => void,
) => {
  if (Capacitor.getPlatform() == 'web') {
    return;
  }
  return PushNotifications.addListener('pushNotificationReceived', callback);
};
