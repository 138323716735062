<script setup lang="ts">
import { IonModal } from '@ionic/vue';

defineProps({
  modelValue: { type: Boolean, required: true },
});

const emit = defineEmits(['update:modelValue']);

function onDidDismiss() {
  emit('update:modelValue', false);
}
</script>

<template>
  <ion-modal :is-open="modelValue" @did-dismiss="onDidDismiss">
    <div class="wrapper">
      <slot></slot>
    </div>
  </ion-modal>
</template>

<style scoped>
.modal-default {
  --width: fit-content;
  --min-width: 250px;
  --max-width: 600px;
  --height: fit-content; /* THIS IS WHAT BREAKS MODALS WITH ION-CONTENT IN THEM (ion-content) */
  --border-radius: 15px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  padding: 15px;
}

.wrapper {
  background-color: white;
}
</style>
