<script setup lang="ts">
import {
  IonCard,
  IonCardHeader,
  IonText,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  modalController,
} from '@ionic/vue';

import { ModalOptions } from '@/lib/modal';
import { PropType } from 'vue';
import MyButton from './MyButton.vue';

defineProps({
  modalOptions: {
    type: Object as PropType<ModalOptions>,
    required: true,
  },
});

const closeModal = (data?: any, role?: string) => {
  modalController.dismiss(data, role);
};
</script>

<template>
  <!-- ion-grid class="ion-text-center modal-wrapper" style="height: 100%">
    <ion-row
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      "
    >
      <ion-col -->
  <ion-card style="margin: 0">
    <ion-card-header :color="modalOptions.color ?? 'primary'">
      <h6
        :style="{
          color: `var(--ion-color-${modalOptions.color ?? 'primary'}-contrast)`,
        }"
        class="ion-no-margin"
      >
        {{ modalOptions.title }}
      </h6>
    </ion-card-header>

    <ion-card-content>
      <ion-grid class="ion-text-center">
        <ion-row>
          <ion-col>
            <ion-text :color="modalOptions.color ?? 'primary'">
              {{ modalOptions.message }}
            </ion-text>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col v-for="button of modalOptions.buttons" :key="button.label">
            <my-button
              @click="closeModal(button.data, button.role)"
              :label="button.label"
              :color="button.color"
              :fill="button.fill"
            ></my-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>
  <!--/ion-col>
    </ion-row>
  </ion-grid-->
</template>

<style scoped>
.modal-default {
  --width: fit-content;
  --min-width: 250px;
  --max-width: 600px;
  --height: fit-content;
  --border-radius: 15px;
  padding: 15px;
}
</style>
