// a function to convert a number to an ordinal string in the format 1st, 2nd, 3rd, 4th, etc.
export function toOrdinalString(num: number): string {
  // get the second last digit of the number
  const secondLastDigit = (num % 100) / 10;
  // get the last digit of the number
  const lastDigit = num % 10;

  if (secondLastDigit != 1) {
    // if the second last digit is not 1, then we can just use the last digit
    switch (lastDigit) {
      case 1:
        return `${num}st`;
      case 2:
        return `${num}nd`;
      case 3:
        return `${num}rd`;
    }
  }

  return `${num}th`;
}
