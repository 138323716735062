import { format } from 'date-fns';
import { timeStr, shortDateStr } from './format-time-date';
import { enAU } from 'date-fns/locale';

// used to display the value collected by a input [type="time"]
// according to locale.  Note: inputValue format is HH:mm (24 hour format)
export function formatTimeInputValue(inputValue: string | undefined) {
  if (inputValue === undefined) return 'UNDEFINED DATE';
  try {
    const [hours, minutes] = inputValue.split(':');
    const temp = new Date();
    temp.setHours(parseInt(hours));
    temp.setMinutes(parseInt(minutes));
    return timeStr(temp);
  } catch {
    return 'Bad Time Value!';
  }
}

// used to display the value collected by a input [type="date"]
// according to locale.  Note: inputValue format is YYYY-MM-DD
export function formatDateInputValue(inputValue: string | Date | undefined) {
  if (inputValue === undefined) return 'UNDEFINED DATE';
  try {
    const temp = new Date(inputValue);
    return shortDateStr(temp);
  } catch {
    return 'Bad Date Value!';
  }
}

export function dateInputToISOString(inputValue: string | undefined) {
  if (inputValue === undefined) return 'UNDEFINED DATE';
  try {
    const date = new Date(inputValue);
    return date.toISOString();
  } catch {
    return 'Bad Date Value!';
  }
}

export function ISOStringToDateInput(inputValue: string | undefined) {
  if (inputValue === undefined) return 'UNDEFINED DATE';
  return inputValue.split('T')[0];
}

export function formatFullDateTimeValue(
  dateValue: string | Date | undefined,
  timeValue: string | undefined = undefined,
  monthFormat = 'MMM',
) {
  if (dateValue === undefined) return 'UNDEFINED DATE';
  try {
    const temp = new Date(dateValue);
    if (timeValue) {
      const [hours, minutes] = timeValue.split(':');
      temp.setHours(parseInt(hours));
      temp.setMinutes(parseInt(minutes));
    }
    // EEEE is the Day of week full string
    // do is the day of the month number with suffix `th or st`
    // yyyy is the year number
    // MMM is the month with only 3 characters
    // p is the time of the day with suffix `AM or PM`
    return format(temp, `EEEE do ${monthFormat} yyyy, p`, {
      locale: enAU,
    }); // shortened format PPPP p but does not include th or st suffix to match requirements
  } catch {
    return 'Bad Date Value!';
  }
}
