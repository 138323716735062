import { BookingResponse } from '@shared/bookings';
import { CustomerResponse } from '@shared/customers';
import { AddressResponse, SchemaResponseFields } from '@shared/util';

export const eventBookingTypes = [
  'wedding',
  'conference',
  'schoolHoliday',
  'corporate',
  'sports',
  'creche',
  'other',
] as const;

export type EventBookingType = (typeof eventBookingTypes)[number];

export const eventBookingTitles = new Map<EventBookingType, string>([
  ['wedding', 'Wedding'],
  ['conference', 'Conference'],
  ['schoolHoliday', 'School Holiday Kids Club'],
  ['corporate', 'Corporate Kids Club'],
  ['sports', 'Sporting Event'],
  ['creche', 'Créche'],
  ['other', 'Other'],
]);

export class BookingDateTimeResponse {
  date: string;
  startTime: string;
  endTime: string;
  numChildren: string;
}

export class EventBookingResponse extends SchemaResponseFields {
  eventType: EventBookingType;
  eventTypeDetails: string;
  eventContactName: string;
  eventContactPhone: string;
  eventContactEmail: string;
  weddingCoordinatorName: string;
  weddingCoordinatorPhone: string;
  address?: AddressResponse;
  addressNotes: string;
  childrenAges: string;
  childrenOutOfNappies: 0 | 1;
  freeParkingAvailable: boolean;
  parkingDetails: string;
  otherDetails: string;
  customer: CustomerResponse | undefined;
  bookingDateTime: BookingDateTimeResponse[];
  booking?: BookingResponse; // Populated when booking is published
}
