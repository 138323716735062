import { createRouter, createWebHistory } from '@ionic/vue-router';
import {
  RouteRecordRaw,
  RouteLocationNamedRaw,
  //  createRouter,
  //  createWebHistory,
} from 'vue-router';

declare module 'vue-router' {
  interface RouteMeta {
    auth: 'always' | 'never' | 'optional';
  }
}

const sitterRoutes: RouteRecordRaw[] = [
  {
    name: 'sitter_available_jobs',
    path: 'available_jobs',
    meta: { auth: 'always' },
    component: () => import('../views/sitter/AvailableJobsPage.vue'),
  },
  {
    name: 'sitter_my_bookings',
    path: 'my_bookings',
    meta: { auth: 'always' },
    component: () => import('../views/sitter/MyBookingsPage.vue'),
  },
  {
    name: 'sitter_calendar',
    path: 'calendar',
    meta: { auth: 'always' },
    component: () => import('../views/sitter/MyCalendarPage.vue'),
  },
  {
    name: 'sitter_booking_update',
    path: 'booking_update/:booking_id',
    meta: { auth: 'always' },
    component: () => import('../views/sitter/BookingUpdatePage.vue'),
  },
  {
    name: 'sitter_bookings_on_date',
    path: 'bookings_on_date/:date',
    meta: { auth: 'always' },
    component: () => import('../views/sitter/BookingsOnDatePage.vue'),
  },
  {
    name: 'sitter_advertisement',
    path: 'advertisement/:id',
    meta: { auth: 'always' },
    component: () => import('../views/sitter/AdvertisementDetailPage.vue'),
  },
  {
    name: 'sitter_my_profile',
    path: 'my_profile',
    meta: { auth: 'always' },
    component: () => import('../views/sitter/MyProfilePage.vue'),
  },
  {
    name: 'sitter_profile_questions',
    path: 'profile_questions',
    meta: { auth: 'always' },
    component: () => import('../views/sitter/ProfileQuestionsPage.vue'),
  },
  {
    name: 'sitter_uploads',
    path: 'uploads',
    meta: { auth: 'always' },
    component: () => import('../views/sitter/UploadsPage.vue'),
  },
  {
    name: 'sitter_support',
    path: 'support',
    meta: { auth: 'always' },
    component: () => import('../views/sitter/SupportPage.vue'),
  },
  {
    name: 'sitter_faq',
    path: 'faq',
    meta: { auth: 'always' },
    component: () => import('../views/sitter/FaqPage.vue'),
  },
  {
    name: 'sitter_contact_us',
    path: 'contact_us',
    meta: { auth: 'always' },
    component: () => import('../views/sitter/ContactUsPage.vue'),
  },
  {
    name: 'sitter_feedback',
    path: 'feedback',
    meta: { auth: 'always' },
    component: () => import('../views/sitter/FeedbackPage.vue'),
  },
  {
    name: 'sitter_video_trainings',
    path: 'video_trainings',
    meta: { auth: 'always' },
    component: () => import('../views/sitter/VideoTrainingsPage.vue'),
  },
  {
    name: 'sitter_resources',
    path: 'resources/:id?',
    meta: { auth: 'always' },
    component: () => import('../views/sitter/ResourcesPage.vue'),
  },
  {
    name: 'sitter_billing',
    path: 'billing',
    meta: { auth: 'always' },
    component: () => import('../views/sitter/BillingPage.vue'),
  },
  {
    name: 'sitter_notifications',
    path: 'notifications',
    meta: { auth: 'always' },
    component: () => import('../views/sitter/MyNotifications.vue'),
  },
  {
    name: 'sitter_complete_job',
    path: 'complete_job/:id',
    meta: { auth: 'always' },
    component: () => import('../views/sitter/CompleteJobPage.vue'),
  },
  {
    name: 'sitter_complete_job_confirmation',
    path: 'complete_job_confirmation',
    meta: { auth: 'always' },
    component: () => import('../views/sitter/CompleteJobConfirmationPage.vue'),
  },
];

const customerRoutes: Array<RouteRecordRaw> = [
  {
    name: 'event_booking',
    path: '/event_booking',
    meta: { auth: 'optional' },
    component: () => import('../views/customer/EventBookingPage.vue'),
  },
  {
    name: 'event_booking_confirmation',
    path: '/event_booking_confirmation',
    meta: { auth: 'optional' },
    component: () =>
      import('../views/customer/EventBookingConfirmationPage.vue'),
  },
  {
    name: 'event_booking_complete',
    path: '/event_booking_complete',
    meta: { auth: 'optional' },
    component: () => import('../views/customer/EventBookingCompletePage.vue'),
  },
  {
    name: 'create_profile',
    path: '/create_profile',
    meta: { auth: 'always' },
    component: () => import('../views/customer/CreateProfilePage.vue'),
  },
  {
    name: 'create_children',
    path: '/create_children',
    meta: { auth: 'always' },
    component: () => import('../views/customer/CreateChildrenPage.vue'),
  },
  {
    name: 'create_account',
    path: '/create_account',
    meta: { auth: 'never' },
    component: () => import('../views/customer/CreateAccountPage.vue'),
  },
  {
    name: 'parent_profile',
    path: '/parent_profile',
    meta: { auth: 'always' },
    component: () => import('../views/customer/ParentProfilePage.vue'),
  },
  {
    name: 'company_profile',
    path: '/company_profile',
    meta: { auth: 'always' },
    component: () => import('../views/customer/CompanyProfilePage.vue'),
  },
  {
    name: 'parent_details',
    path: '/parent_details/:parent_id?',
    meta: { auth: 'always' },
    component: () => import('../views/customer/ParentDetailsPage.vue'),
  },
  {
    name: 'booking_type',
    path: '/booking_type',
    meta: { auth: 'always' },
    component: () => import('../views/customer/BookingTypePage.vue'),
  },
  {
    name: 'casual_booking',
    path: '/casual_booking',
    meta: { auth: 'always' },
    component: () => import('../views/customer/CasualBookingPage.vue'),
  },
  {
    name: 'party_booking',
    path: '/party_booking',
    meta: { auth: 'always' },
    component: () => import('../views/customer/PartyBookingPage.vue'),
  },
  {
    name: 'casual_booking_confirmation',
    path: '/casual_booking_confirmation',
    meta: { auth: 'always' },
    component: () =>
      import('../views/customer/CasualBookingConfirmationPage.vue'),
  },
  {
    name: 'complete_booking_payment',
    path: '/complete_booking_payment/:id',
    meta: { auth: 'always' },
    component: () => import('../views/customer/CasualBookingPaymentPage.vue'),
  },
  {
    name: 'casual_booking_manual_handling',
    path: '/casual_booking_manual_handling',
    meta: { auth: 'always' },
    component: () =>
      import('../views/customer/CasualBookingManualHandlingPage.vue'),
  },
  {
    name: 'party_booking_confirmation',
    path: '/party_booking_confirmation',
    meta: { auth: 'always' },
    component: () =>
      import('../views/customer/PartyBookingConfirmationPage.vue'),
  },
  {
    name: 'party_booking_success',
    path: '/party_booking_success',
    meta: { auth: 'always' },
    component: () => import('../views/customer/PartyBookingSuccessPage.vue'),
  },
  {
    name: 'casual_booking_success',
    path: '/casual_booking_success',
    meta: { auth: 'always' },
    component: () => import('../views/customer/CasualBookingSuccessPage.vue'),
  },
  {
    name: 'booking_update_status',
    path: '/booking_update_status/:status',
    meta: { auth: 'always' },
    component: () =>
      import('../views/customer/BookingUpdateConfirmationStatus.vue'),
  },
  {
    name: 'support_success',
    path: '/support_success',
    meta: { auth: 'always' },
    component: () => import('../views/customer/SupportSuccess.vue'),
  },
  {
    name: 'ongoing_booking_process',
    path: '/ongoing_booking_process',
    meta: { auth: 'always' },
    component: () => import('../views/customer/OngoingBookingProcessPage.vue'),
  },
  {
    name: 'ongoing_booking',
    path: '/ongoing_booking',
    meta: { auth: 'always' },
    component: () => import('../views/customer/OngoingBookingPage.vue'),
  },
  {
    name: 'ongoing_booking_confirmation',
    path: '/ongoing_booking_confirmation',
    meta: { auth: 'always' },
    component: () =>
      import('../views/customer/OngoingBookingConfirmationPage.vue'),
  },
  {
    name: 'ongoing_booking_success',
    path: '/ongoing_booking_success',
    meta: { auth: 'always' },
    component: () => import('../views/customer/OngoingBookingSuccessPage.vue'),
  },
  {
    name: 'casual_booking_payment',
    path: '/casual_booking_payment',
    meta: { auth: 'always' },
    component: () => import('../views/customer/CasualBookingPaymentPage.vue'),
  },

  {
    name: 'ongoing_booking_payment',
    path: '/ongoing_booking_payment',
    meta: { auth: 'always' },
    component: () => import('../views/customer/OngoingBookingPaymentPage.vue'),
  },
  {
    name: 'booking_list',
    path: '/booking_list',
    meta: { auth: 'always' },
    component: () => import('../views/customer/BookingListPage.vue'),
  },
  {
    name: 'booking_details',
    path: '/booking_details/:booking_id',
    meta: { auth: 'always' },
    component: () => import('../views/customer/BookingDetailsPage.vue'),
  },
  {
    name: 'edit_booking',
    path: '/edit_booking/:booking_id',
    meta: { auth: 'always' },
    component: () => import('../views/customer/EditBookingPage.vue'),
  },
  {
    name: 'booking_update_declined',
    path: '/booking_update_declined/:booking_id',
    meta: { auth: 'always' },
    component: () => import('../views/customer/BookingUpdateDeclinedPage.vue'),
  },
  {
    name: 'customer_sitter_feedback',
    path: '/sitter_feedback/:booking_id',
    meta: { auth: 'always' },
    component: () => import('../views/customer/SitterFeedbackPage.vue'),
  },
  {
    name: 'sitter_profile',
    path: '/sitter_profile/:sitter_id',
    meta: { auth: 'always' },
    component: () => import('../views/customer/SitterProfilePage.vue'),
  },
  {
    name: 'favourite_sitters',
    path: '/favourite_sitters',
    meta: { auth: 'always' },
    component: () => import('../views/customer/FavouriteSittersPage.vue'),
  },
  /* THIS ROUTE IS UNUSED AS OF 2024-01-17. 
  THE ASSOCIATED COMPONENT IS ALSO UNUSED, BUT THE FILE STILL EXISTS.
  {
    name: 'billing',
    path: '/billing',
    meta: { auth: 'always' },
    component: () => import('../views/customer/BillingPage.vue'),
  },
  */
  {
    name: 'past_payments',
    path: '/past_payments',
    meta: { auth: 'always' },
    component: () => import('../views/customer/PastPaymentsPage.vue'),
  },
  {
    name: 'support',
    path: '/support',
    meta: { auth: 'always' },
    component: () => import('../views/customer/SupportPage.vue'),
  },
  {
    name: 'information',
    path: '/information/:tag',
    meta: { auth: 'optional' },
    component: () => import('../views/customer/InformationPage.vue'),
  },
  {
    name: 'faq',
    path: '/faq',
    meta: { auth: 'optional' },
    component: () => import('../views/customer/FaqPage.vue'),
  },
  {
    name: 'contact_us',
    path: '/contact_us',
    meta: { auth: 'always' },
    component: () => import('../views/customer/ContactUsPage.vue'),
  },
  {
    name: 'feedback',
    path: '/feedback',
    meta: { auth: 'always' },
    component: () => import('../views/customer/FeedbackPage.vue'),
  },
  {
    name: 'tell_a_friend',
    path: '/tell_a_friend',
    meta: { auth: 'always' },
    component: () => import('../views/customer/TellAFriendPage.vue'),
  },
];

function embedRoutes(
  parentRouteName: string,
  childRoutes: Array<RouteRecordRaw>,
) {
  const embeddedRoutes: Array<RouteRecordRaw> = [];
  for (const childRoute of childRoutes) {
    // if the child route starts with a slash, get the path without the leading slash
    let childRoutePath = childRoute.path;
    if (childRoute.path.startsWith('/')) {
      childRoutePath = childRoute.path.substring(1);
    }

    embeddedRoutes.push({
      ...childRoute,
      name: `${parentRouteName}|${String(childRoute.name)}`,
      path: childRoutePath,
    });
  }
  return embeddedRoutes;
}

const appPages: Array<RouteRecordRaw> = [
  {
    // the root redirect to login.
    path: '',
    redirect: '/login',
    meta: { auth: 'optional' },
  },
  {
    name: 'login',
    path: '/login',
    meta: { auth: 'never' },
    component: () => import('../views/LoginPage.vue'),
  },
  {
    name: 'forgot_password',
    path: '/forgot_password',
    meta: { auth: 'never' },
    component: () => import('../views/ForgotPasswordPage.vue'),
  },
  {
    name: 'reset_sent',
    path: '/reset_sent',
    meta: { auth: 'never' },
    component: () => import('../views/ResetEmailSentPage.vue'),
  },
  {
    name: 'reset_password',
    path: '/reset_password/:token', // token for the reset password
    meta: { auth: 'never' },
    component: () => import('../views/ResetPasswordPage.vue'),
  },
  {
    name: 'reset_success',
    path: '/reset_success',
    meta: { auth: 'never' },
    component: () => import('../views/ResetPasswordSuccess.vue'),
  },
  {
    name: 'change_password',
    path: '/change_password',
    meta: { auth: 'always' },
    component: () => import('../views/ChangePasswordPage.vue'),
  },
  {
    name: 'parent_dashboard',
    path: '/parent_dashboard',
    meta: { auth: 'always' },
    component: () => import('../views/customer/ParentDashboardPage.vue'),
  },

  ...customerRoutes,

  // *********************************
  // Sitter Routes
  // *********************************
  // *********************************
  {
    name: 'sitter_create_profile',
    path: '/sitter/create_profile',
    meta: { auth: 'never' },
    component: () => import('../views/sitter/MyProfilePage.vue'),
  },
  {
    path: '/sitter',
    component: () => import('../layout/sitter/SideMenu.vue'),
    children: sitterRoutes,
  },

  // *********************************
  // admin Routes
  // *********************************
  // *********************************
  {
    path: '/admin',
    component: () => import('../layout/admin/AdminMenu.vue'),
    children: [
      {
        name: 'admin_tag',
        path: 'tag',
        meta: { auth: 'always', adminRoute: 'site_admin' },
        component: () => import('../views/admin/TagsPage.vue'),
      },
      {
        name: 'admin_faq',
        path: 'faq/:user_type',
        meta: { auth: 'always', adminRoute: 'site_admin' },
        component: () => import('../views/admin/faq/FAQPage.vue'),
      },
      {
        name: 'admin_faq_edit',
        path: 'faq/edit/:id',
        meta: { auth: 'always', adminRoute: 'site_admin' },
        component: () => import('../views/admin/faq/FAQEdit.vue'),
      },
      {
        name: 'admin_faq_add',
        path: 'faq/add/:user_type',
        meta: { auth: 'always', adminRoute: 'site_admin' },
        component: () => import('../views/admin/faq/FAQEdit.vue'),
      },
      {
        name: 'infopage',
        path: 'infopage/:tag',
        meta: { auth: 'always', adminRoute: 'site_admin' },
        component: () => import('../views/admin/info/InfoPage.vue'),
      },
      {
        name: 'infopage_edit',
        path: 'infopage_edit/:tag',
        meta: { auth: 'always', adminRoute: 'site_admin' },
        component: () => import('../views/admin/info/InfoPageEdit.vue'),
      },
      {
        name: 'admin_site_admin',
        path: 'site_admin',
        meta: { auth: 'always', adminRoute: 'site_admin' },
        component: () => import('../views/admin/SiteAdminPage.vue'),
      },
      {
        name: 'admin_notifications',
        path: 'notifications',
        meta: { auth: 'always', adminRoute: 'notifications' },
        component: () =>
          import('../views/admin/notification/NotificationsPage.vue'),
      },
      {
        name: 'admin_resources',
        path: 'resources',
        meta: { auth: 'always' },
        component: () => import('../views/admin/resource/ResourceListPage.vue'),
      },
      {
        name: 'admin_add_resources',
        path: 'resources/add/:id?',
        meta: { auth: 'always' },
        component: () => import('../views/admin/resource/AddResourcePage.vue'),
      },
      {
        name: 'admin_edit_resources',
        path: 'resources/edit/:id',
        meta: { auth: 'always' },
        component: () => import('../views/admin/resource/EditResourcePage.vue'),
      },
      {
        path: 'booking_list',
        meta: { auth: 'always', adminRoute: 'admin_bookings' },
        component: () => import('../layout/admin/BookingListLayout.vue'),
        children: [
          {
            name: 'admin_booking_list',
            path: '',
            meta: { auth: 'always' },
            redirect: { name: 'admin_published_booking_list' },
          },
          {
            name: 'admin_published_booking_list',
            path: 'published',
            meta: { auth: 'always' },
            component: () =>
              import(
                '../views/admin/published-booking/PublishedBookingListPage.vue'
              ),
          },
          {
            name: 'admin_ongoing_booking_list',
            path: 'ongoing',
            meta: { auth: 'always' },
            component: () =>
              import(
                '../views/admin/ongoing-booking/OngoingBookingListPage.vue'
              ),
          },
          {
            name: 'admin_event_booking_list',
            path: 'event',
            meta: { auth: 'always' },
            component: () =>
              import('../views/admin/event-booking/EventBookingListPage.vue'),
          },
          {
            name: 'admin_party_booking_list',
            path: 'party',
            meta: { auth: 'always' },
            component: () =>
              import('../views/admin/party-booking/PartyBookingListPage.vue'),
          },
        ],
      },
      {
        path: 'customers',
        meta: { auth: 'always', adminRoute: 'admin_customers' },
        component: () => import('../views/admin/customer/CustomersPage.vue'),
        children: [
          // When changing between embedded route parents, there is no subroute
          // specified, so we need to explicitly mount the blank page
          {
            name: 'admin_customers',
            path: '',
            meta: { auth: 'always' },
            component: () => import('../views/admin/BlankEmbeddedPage.vue'),
          },
          {
            name: 'admin_customer_details',
            path: 'customer_details',
            meta: { auth: 'always' },
            component: () =>
              import('../views/admin/customer/CustomerDetailsPage.vue'),
          },
          {
            // this routes to the customer creation page, but has
            // a different auth requirement to the standard new customer
            // route, so it is defined as a new route with a different
            // name.
            name: 'admin_create_customer',
            path: '/create_customer',
            meta: { auth: 'always' },
            component: () => import('../views/customer/CreateAccountPage.vue'),
          },
          ...embedRoutes('admin_customers', [
            ...customerRoutes,
            // Manual redirect for parent_dashboard route when used within admin
            {
              name: 'parent_dashboard',
              path: 'parent_dashboard',
              meta: { auth: 'always' },
              component: () =>
                import('../views/admin/customer/CustomerDetailsPage.vue'),
            },
          ]),
        ],
      },
      {
        path: 'sitters',
        meta: { auth: 'always', adminRoute: 'admin_sitters' },
        component: () => import('../views/admin/sitter/SittersPage.vue'),
        children: [
          // When changing between embedded route parents, there is no subroute
          // specified, so we need to explicitly mount the blank page
          {
            name: 'admin_sitters',
            path: '',
            meta: { auth: 'always' },
            component: () => import('../views/admin/BlankEmbeddedPage.vue'),
          },
          ...embedRoutes('admin_sitters', sitterRoutes),
          // Send Message Route for Sitters
          {
            name: 'admin_notify_sitter',
            path: 'admin_notify_sitter',
            meta: { auth: 'always' },
            component: () =>
              import('../views/admin/notification/SendNotification.vue'),
          },
          {
            name: 'admin_sitter_details',
            path: 'sitter_details',
            meta: { auth: 'always' },
            component: () =>
              import('../views/admin/sitter/SitterDetailsPage.vue'),
          },
          {
            name: 'admin_sitter_status',
            path: 'sitter_status',
            meta: { auth: 'always' },
            component: () =>
              import('../views/admin/sitter/SitterStatusPage.vue'),
          },
        ],
      },
      {
        name: 'admin_published_booking_detail',
        path: 'booking_detail/:id',
        meta: { auth: 'always', adminRoute: 'admin_bookings' },
        component: () =>
          import(
            '../views/admin/published-booking/PublishedBookingDetailPage.vue'
          ),
      },
      {
        name: 'admin_published_booking_status_log',
        path: 'booking_status_log/:id',
        meta: { auth: 'always', adminRoute: 'admin_bookings' },
        component: () =>
          import(
            '../views/admin/published-booking/PublishedBookingStatusLog.vue'
          ),
      },
      /* ADMIN ONGOING BOOKING ROUTES */
      {
        name: 'admin_ongoing_booking_detail',
        path: 'ongoing_booking_detail/:id',
        meta: { auth: 'always', adminRoute: 'admin_bookings' },
        component: () =>
          import('../views/admin/ongoing-booking/OngoingBookingDetailPage.vue'),
      },
      {
        name: 'admin_ongoing_booking_edit',
        path: 'ongoing_booking_edit/:id',
        meta: { auth: 'always', adminRoute: 'admin_bookings' },
        component: () =>
          import('../views/admin/ongoing-booking/OngoingBookingEditPage.vue'),
      },
      {
        name: 'admin_ongoing_booking_edit_confirmation',
        path: 'ongoing_booking_edit_confirmation',
        meta: { auth: 'always', adminRoute: 'admin_bookings' },
        component: () =>
          import(
            '../views/admin/ongoing-booking/OngoingBookingEditConfirmationPage.vue'
          ),
      },
      {
        name: 'admin_ongoing_booking_edit_success',
        path: 'ongoing_booking_edit_success',
        meta: { auth: 'always', adminRoute: 'admin_bookings' },
        component: () =>
          import(
            '../views/admin/ongoing-booking/OngoingBookingEditSuccessPage.vue'
          ),
      },

      /* ADMIN EVENT BOOKING ROUTES */
      {
        name: 'admin_event_booking_detail',
        path: 'event_booking_detail/:id',
        meta: { auth: 'always', adminRoute: 'admin_bookings' },
        component: () =>
          import('../views/admin/event-booking/EventBookingDetailPage.vue'),
      },
      {
        name: 'admin_event_booking_edit',
        path: 'event_booking_edit/:id',
        meta: { auth: 'always', adminRoute: 'admin_bookings' },
        component: () =>
          import('../views/admin/event-booking/EventBookingEditPage.vue'),
      },
      {
        name: 'admin_event_booking_edit_confirmation',
        path: 'event_booking_edit_confirmation',
        meta: { auth: 'always', adminRoute: 'admin_bookings' },
        component: () =>
          import(
            '../views/admin/event-booking/EventBookingEditConfirmationPage.vue'
          ),
      },
      {
        name: 'admin_event_booking_edit_success',
        path: 'event_booking_edit_success',
        meta: { auth: 'always', adminRoute: 'admin_bookings' },
        component: () =>
          import(
            '../views/admin/event-booking/EventBookingEditSuccessPage.vue'
          ),
      },

      /* ADMIN PARTY BOOKING ROUTES */
      {
        name: 'admin_party_booking_detail',
        path: 'party_booking_detail/:id',
        meta: { auth: 'always', adminRoute: 'admin_bookings' },
        component: () =>
          import('../views/admin/party-booking/PartyBookingDetailPage.vue'),
      },
      {
        name: 'admin_party_booking_edit',
        path: 'party_booking_edit/:id',
        meta: { auth: 'always', adminRoute: 'admin_bookings' },
        component: () =>
          import('../views/admin/party-booking/PartyBookingEditPage.vue'),
      },
      {
        name: 'admin_party_booking_edit_confirmation',
        path: 'party_booking_edit_confirmation',
        meta: { auth: 'always', adminRoute: 'admin_bookings' },
        component: () =>
          import(
            '../views/admin/party-booking/PartyBookingEditConfirmationPage.vue'
          ),
      },
      {
        name: 'admin_party_booking_edit_success',
        path: 'party_booking_edit_success',
        meta: { auth: 'always', adminRoute: 'admin_bookings' },
        component: () =>
          import(
            '../views/admin/party-booking/PartyBookingEditSuccessPage.vue'
          ),
      },
      /* ADMIN PARTY BOOKING ROUTES END*/

      {
        name: 'admin_offered_sitters',
        path: 'offered_sitters/:id',
        meta: { auth: 'always', adminRoute: 'admin_bookings' },
        component: () => import('../views/admin/OfferedSittersPage.vue'),
      },
      {
        name: 'admin_alternate_offers',
        path: 'alternate_offers/:id',
        meta: { auth: 'always', adminRoute: 'admin_bookings' },
        component: () => import('../views/admin/AlternateOffersPage.vue'),
      },
      {
        name: 'admin_users',
        path: 'users',
        meta: { auth: 'always', adminRoute: 'site_admin' },
        component: () => import('../views/admin/UsersPage.vue'),
      },
      {
        name: 'admin_connect_xero',
        path: 'connect_xero',
        //meta: { auth: "always" },
        meta: { auth: 'optional', adminRoute: 'site_admin' }, // FIXME: this is just here for testing, should be set to "always"
        component: () => import('../views/admin/ConnectXeroPage.vue'),
      },
      {
        name: 'admin_xero_connected',
        path: 'xero_connected',
        //meta: { auth: "always" },
        meta: { auth: 'optional', adminRoute: 'site_admin' }, // FIXME: this is just here for testing, should be set to "always"
        component: () => import('../views/admin/XeroConnectSuccessPage.vue'),
      },
      {
        name: 'admin_contact_us',
        path: 'contact_us',
        meta: { auth: 'always', adminRoute: 'site_admin' },
        component: () => import('../views/admin/contact-us/ContactUsPage.vue'),
      },
      {
        name: 'admin_contact_us_edit',
        path: 'contact_us_edit',
        meta: { auth: 'always', adminRoute: 'site_admin' },
        component: () => import('../views/admin/contact-us/ContactUsEdit.vue'),
      },

      /*
      {
        name: 'admin_manual_booking',
        path: 'manual_booking',
        meta: { auth: 'always' },
        component: () => import('../views/admin/ManualBookingPage.vue'),
      },
      */
    ],
  },

  // ******************************
  // THIS MUST BE THE LAST ROUTE!
  // ******************************
  {
    // a catch-all route to redirect to the root node.
    path: '/:catchAll(.*)',
    redirect: '/',
  },
];

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: appPages,
});

// a router guard that redirects to the login page if the user is not logged in, and redirects to the dashboard if they are logged in but the meta field is set to "never"
router.beforeEach((to, from, next) => {
  console.log('router.beforeEach, to:', to.name, 'from:', from.name);
  if (to.meta.auth === 'always' && !localStorage.getItem('token')) {
    console.log('redirecting to login');
    next({ name: 'login' });
  } else if (to.meta.auth === 'never' && localStorage.getItem('token')) {
    // check if the user is a parent or a sitter
    if (localStorage.getItem('userType') === 'Sitter') {
      console.log('Auth guard redirecting to sitter_available_jobs');
      next({ name: 'sitter_available_jobs' });
    } else if (localStorage.getItem('userType') === 'AdminGroup') {
      console.log('Auth guard redirecting to admin_notifications');
      next({ name: 'admin_notifications' });
    } else {
      console.log('Auth guard redirecting to parent_dashboard');
      next({ name: 'parent_dashboard' });
    }
  } else {
    console.log('No redirect in auth route guard.');
    next();
  }
});

// a router guard to redirect for embedded admin routes
router.beforeEach((to, from, next) => {
  // check if the current route has a pipe, which indicates
  // it has been embedded.
  console.log(
    'router.beforeEach embedded route guard, from:',
    from.name,
    'to:',
    to.name,
  );
  if (
    from.name &&
    typeof from.name === 'string' &&
    to.name &&
    typeof to.name === 'string'
  ) {
    const fromRouteComponents = from.name?.split('|');
    const toRouteComponents = to.name?.split('|');
    if (fromRouteComponents.length == 2 && toRouteComponents.length == 1) {
      const proposedVirtualRouteName = `${fromRouteComponents[0]}|${toRouteComponents[0]}`;
      if (router.hasRoute(proposedVirtualRouteName)) {
        console.log('redirecting to virtual route:', proposedVirtualRouteName);
        // copy the representee query parameter from the 'from route' to the 'to route'
        if ('representee' in from.query) {
          to.query.representee = from.query.representee;
        }
        next({
          name: proposedVirtualRouteName,
          query: to.query,
          params: to.params,
        });
      } else {
        console.log('No redirect in embedded route guard.');
        next();
      }
    } else {
      console.log('No redirect in embedded route guard.');
      next();
    }
  } else {
    console.log('No redirect in embedded route guard.');
    next();
  }
});

/* for reference, there are 3 types of raw route objects from vue-router.d.ts:
RouteLocationRaw which is defined as: string | RouteLocationPathRaw | RouteLocationNamedRaw
RouteLocationPathRaw which is defined as: { path: string; query?: RawQuery; hash?: string; params?: RawParams; }
RouteLocationNamedRaw which is defined as: { name: RouteName; query?: RawQuery; hash?: string; params?: RawParams; }

we only accept string or RouteLocationNamedRaw for now, because routing is done by name.
*/
export function appRouteTo(route: string | RouteLocationNamedRaw) {
  if (typeof route === 'string') {
    return router.push({ name: route });
  } else {
    return router.push(route);
  }
}
